const advancedItems = $('#advancedItems');

$(() => {
  // Show tooltips on titles width data-toggles
  $('[data-toggle="tooltip"]').tooltip();
  //$('[data-toggle="popover"]').popover();
  $('.navmenu .close').click(function () {
    $('.navmenu').offcanvas('hide');
  })
});

$(() => {
  // Bootstrap menu magic
  if ($(window).width() < 767) {
    $(".dropdown-toggle").attr('data-toggle', 'dropdown');

    $('.dropdown').on('show.bs.dropdown', function () {
      $(this).siblings('.show').removeClass('show').find('.dropdown-toggle').attr('data-toggle', 'dropdown');
      $(this).find('a.dropdown-toggle').removeAttr('data-toggle');
    });
  }
});

// All functions after page loaded
$(document).ready(function () {
  // scroll to top
  $(() => {
    const toTop = $('#toTop');
    if (toTop.length) {
      const scrollTrigger = 100, // px
        backToTop = function () {
          let scrollTop = $(window).scrollTop();
          if (scrollTop > scrollTrigger) {
            toTop.show();
          } else {
            toTop.hide();
          }
        };
      backToTop();
      $(window).on('scroll', function () {
        backToTop();
      });
      toTop.on('click', function (e) {
        e.preventDefault();
        $('html,body').animate({
          scrollTop: 0
        }, 800);
      });
    }
  });

  /*
  * Replace all SVG images with inline SVG
  */
  $('img.svg').each(function () {
    const $img = $(this),
      imgID = $img.attr('id'),
      imgClass = $img.attr('class'),
      imgURL = $img.attr('src');

    $.get(imgURL, function (data) {
      // Get the SVG tag, ignore the rest
      let $svg = $(data).find('svg');

      // Add replaced image's ID to the new SVG
      if (typeof imgID !== 'undefined') {
        $svg = $svg.attr('id', imgID);
      }
      // Add replaced image's classes to the new SVG
      if (typeof imgClass !== 'undefined') {
        $svg = $svg.attr('class', imgClass + ' replaced-svg');
      }

      // Remove any invalid XML tags as per http://validator.w3.org
      $svg = $svg.removeAttr('xmlns:a');

      // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
      if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
        $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
      }

      // Replace image with new SVG
      $img.replaceWith($svg);

    }, 'xml');

  });

  $('#openSearch').click(function () {
    $('#searchArea').css({
      'display': 'block',
      'opacity': 1,
      'height': 'auto',
      'transform': 'translateY(0)'
    });
  });

  $('#searchArea .close').click(function () {
    $('#searchArea').css('transform', 'translateY(-100%)');
  });


  // Grid to List View
  $(() => {
    const $product = $('.product-list .product');
    $('#listViewButton').click(function (event) {
      event.preventDefault();
      $product.addClass('list-group-item');
    });
    $('#gridViewButton').click(function (event) {
      event.preventDefault();
      $product.removeClass('list-group-item');
      $product.addClass('grid-group-item');
    });
  });

  // Pagination styles
  $(() => {
    const $paginationLi = $('.pagination li'),
      $paginationA = $('.pagination a');
    if (!$paginationLi.hasClass('page-item')) {
      $paginationLi.addClass('page-item');
    }
    if (!$paginationA.hasClass('page-link')) {
      $paginationA.addClass('page-link');
    }
    $paginationLi.each(function () {
      if ($(this).hasClass('selected')) {
        $(this).removeClass('selected').addClass('active')
      }
    })
  });

  //Show filters on large screens
  $(() => {
    const $filtersBar = $('.filters-form-wrapper.collapse');
    if ($filtersBar.length) {
      if ($(window).width() > 992) {
        $filtersBar.addClass('show')
      }
    }
  });

  //Show counters in filters
  $(() => {
    const $fs = $('.filters');
    if ($fs.length && $(window).width() > 576) {
      $fs.on('change', 'input,select', function () {
        let that = $(this);
        let params_ar = $(this).closest('form').serializeArray();
        let params = [];
        let last_added_param = '';
        for (let i = 0; i < params_ar.length; i++) {
          if (params_ar[i].value !== '') {
            let tmp_name = params_ar[i].name;
            if (tmp_name.substring(tmp_name.length - 6) == '[unit]' && tmp_name.replace(/\[.*/, '') != last_added_param) {
              continue;
            }
            params.push(params_ar[i].name + '=' + params_ar[i].value);
            last_added_param = tmp_name.replace(/\[.*/, '');
          }
        }
        params = params.join('&');
        // if (params.length) {
        //     $($fs + ' a.filters-reset').show();
        // } else {
        //     $($fs + ' a.filters-reset').hide();
        // }
        let qparams = 'hash=' + $.wa.filters.hash + '&' + params;
        if ($.wa.filters.category_id) {
          qparams = 'category_id=' + $.wa.filters.category_id + '&' + params;
        }

        let that_parent = that.parent();

        $.get($.wa.filters.url, qparams, function (response) {
          $.wa.filters.features = response.data.features;
          //disableFilters();
          let tmp = ((that.get(0).nodeName == 'SELECT' && that.offset().top > 0) || that.is('input:text'))
            ? that : that_parent;
          if ($.wa.filters.counter) {
            $.wa.filters.counter(response.data, params, tmp);
          } else {
            let c = that.parent('div');
            //console.log(c.data());
            c.data('content', response.data.count);
            $('[data-toggle=popover]').not(c).popover('hide');
            c.popover('show');
          }
        }, 'json');

      });
    }
  });

  // Hide all popovers n document click
  $(document).click(function () {
    $('[data-toggle="popover"]').popover('hide');
  });

  // Show more button
  $(document).on("click touchend", "#loadCategoryMore", function () {
    const paging = $('.paging-nav');
    let times = 0;
    const current = paging.find('li.active');
    const win = $(window);
    let next = current.next();

    if (!paging.length) {
      return;
    }
    $("#loadCategoryMore .fa-sync").addClass("fa-spin");

    win.lazyLoad('stop');

    if (next.length) {
      win.lazyLoad({
        container: '.downloaded-list',
        load: function () {
          win.lazyLoad('sleep');

          let paging = $('.paging-nav');
          const current = paging.find('li.active');
          let next = current.next();
          let url = next.find('a').attr('href');
          if (!url) {
            win.lazyLoad('stop');
            return;
          }

          const product_list = $('.downloaded-list');
          $.ajax({
            url: url,
            type: 'GET',
            success: function (html) {
              let tmp = $('<div></div>').html(html);
              /*if ($.Retina) {
                  tmp.find('.downloaded-list img').retina();
              }*/

              let step = 1;
              const tmp_paging = tmp.find('.paging-nav');
              const current = tmp_paging.find('li.selected');
              let next = current.next();

              let blocks = tmp.find('.downloaded-list').children();
              product_list.append(blocks);
              paging.replaceWith(tmp_paging);
              paging = tmp_paging;
              times -= 1;

              paging.find('li.active').prevAll().addClass("pseudo-active");
              if (next.length) {
                if (!isNaN(times) && times <= 0) {
                  win.lazyLoad('sleep');
                } else {
                  win.lazyLoad('wake');
                }
              } else {
                $("#loadCategoryMore").remove();
                win.lazyLoad('stop');
                console.log('stop');
              }
              $("#loadCategoryMore .fa-sync").removeClass("fa-spin");
              tmp.remove();
              const $paginationLi = $('.pagination li'),
                $paginationA = $('.pagination a');
              if (!$paginationLi.hasClass('page-item')) {
                $paginationLi.addClass('page-item');
              }
              if (!$paginationA.hasClass('page-link')) {
                $paginationA.addClass('page-link');
              }
              $paginationLi.each(function () {
                if ($(this).hasClass('selected')) {
                  $(this).removeClass('selected').addClass('active')
                }
              })
            },
            error: function (data) {
              console.log('Error: ' + data)
            }
          });
        }
      });
    }
  });

  // add lightbox to product images
  if ($('.product-images').length) {
    lightbox.option({
      'albumLabel': false
    })
  }

  //Toggle collapse symbol
  $(() => {
    const symbol = $('.collapsing-bar');
    symbol.click(function () {
      $(this).find('span').toggleClass("fa-plus fa-minus", 1000);
    });
  });

  // Put the styles link to head
  $('head').append($('<link rel="stylesheet" type="text/css" />').attr('href', '/wa-data/public/site/themes/default/css/styles.css'));
  // signalize this script is loaded

  let myLazyLoad = new LazyLoad({elements_selector: "[data-src]"});

  // Popup for buy button where SKUs detected
  // $('.product-list__item-btn_popup').on('click', function() {
  //   if ($(window).width() <= 1000) return;
  //   var _action = $(this).attr("data-popup");
  //   var _this = $(this);
  //   if (window.location.pathname.toLowerCase().indexOf(_action) >= 0) return;
  //   $.get(_action, {type: "productpopup"}, function (response) {
  //     u = !0;
  //     $(".maincontent").parent().append(response);
  //     console.log('response:', response);
  //   });
  //   console.log('_action:', _action);
  //   return false;
  // });

  console.log("Helper done...");
});

$(window).on('load', function () {
  // Product images
  var carousel = $(".owl-gallery-1");
  var slider = $('.owl-slider-1');
  if (carousel.length) {
    /*
    carousel.owlCarousel({
      margin: 10,
      loop: false,
      nav: true,
      dots: true,
      //stagePadding: 40,
      responsive: {
        0: {
          items: 3
        },
        600: {
          items: 4
        },
        1000: {
          items: 5
        }
      },
      autoplay: false,
      //center: true,
      onChanged: attachmentChangeCarousel
    });

    slider.owlCarousel({
        items: 1,
        loop: false,
        nav: true,
        dots: true,
        autoplay: false,
        onChanged: attachmentChangeGallery
    });

    function attachmentChangeCarousel (event) {
      var itemCarousel = event.page.index;
      //console.log('itemCarousel:'+itemCarousel);
      $(".owl-slider-1").trigger('to.owl.carousel', [itemCarousel])
    }

    function attachmentChangeGallery (event) {
      var itemGallery = event.page.index;
      //console.log('itemGallery:'+itemGallery);
      $(".owl-gallery-1").trigger('to.owl.carousel', [itemGallery])
    }

    carousel.on('click', '.owl-item', function () {
      var click = $(this).index() - $('.owl-gallery-1 .owl-stage-outer .owl-stage').find('.owl-item.cloned').length / 2; //ловлю клоны
      //console.log(click);
      carousel.trigger('to.owl.carousel', click)
    });
    */
    var sync1 = slider;
    var sync2 = carousel;
    var slidesPerPage = 4; //globaly define number of elements per page
    var syncedSecondary = true;

    sync1.owlCarousel({
      items : 1,
      slideSpeed : 2000,
      nav: true,
      autoplay: false,
      dots: false,
      loop: true,
      responsiveRefreshRate : 200,
    }).on('changed.owl.carousel', syncPosition);

    sync2
      .on('initialized.owl.carousel', function () {
        sync2.find(".owl-item").eq(0).addClass("current");
      })
      .owlCarousel({
        items : slidesPerPage,
        dots: false,
        nav: true,
        smartSpeed: 200,
        slideSpeed : 500,
        slideBy: slidesPerPage, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
        responsiveRefreshRate : 100,
        margin: 10,
        responsive: {
          0: {
            items: slidesPerPage - 1
          },
          600: {
            items: slidesPerPage
          },
          1000: {
            items: slidesPerPage + 1
          }
        },
      }).on('changed.owl.carousel', syncPosition2);

    function syncPosition(el) {
      //if you set loop to false, you have to restore this next line
      //var current = el.item.index;

      //if you disable loop you have to comment this block
      var count = el.item.count-1;
      var current = Math.round(el.item.index - (el.item.count/2) - .5);

      if(current < 0) {
        current = count;
      }
      if(current > count) {
        current = 0;
      }

      //end block

      sync2
        .find(".owl-item")
        .removeClass("current")
        .eq(current)
        .addClass("current");
      var onscreen = sync2.find('.owl-item.active').length - 1;
      var start = sync2.find('.owl-item.active').first().index();
      var end = sync2.find('.owl-item.active').last().index();

      if (current > end) {
        sync2.data('owl.carousel').to(current, 100, true);
      }
      if (current < start) {
        sync2.data('owl.carousel').to(current - onscreen, 100, true);
      }
    }

    function syncPosition2(el) {
      if(syncedSecondary) {
        var number = el.item.index;
        sync1.data('owl.carousel').to(number, 100, true);
      }
    }

    sync2.on("click", ".owl-item", function(e){
      e.preventDefault();
      var number = $(this).index();
      sync1.data('owl.carousel').to(number, 300, true);
    });
  }
  // advanced items (champions) on homepage
  if (advancedItems.length) {
    advancedItems.owlCarousel({
      loop: true,
      margin: 10,
      dots: false,
      nav: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 2
        },
        600: {
          items: 3
        },
        1000: {
          items: 4
        }
      }
    });
  }

  $("#loading").fadeOut("slow");
});